import { keyBy } from 'lodash';

const ADMIN = "Admin";
const TEAM_MEMBER = "Team Member";
const GUEST = "Guest";
const LOGIN = "Login";
const WORKLOAD_MANAGER = "Workload Manager";
const TIMESHEET_MANAGER = "Timesheet Manager";
const TEAM_BUDGET_MANAGER = "Team Budget Manager";
const WORKLOAD_MANAGER_VIEW_ONLY = "Workload Manager View Only";
const FINANCIAL_MANAGER = "Financial Manager";
const FINANCIAL_MANAGER_VIEW_ONLY = "Financial Manager View Only";

export const ACCESS_ROLES = [
  {
    label: ADMIN,
    roleId: 1,
  },
  {
    label: TEAM_MEMBER,
    roleId: 2,
  },
  {
    label: GUEST,
    roleId: 4,
  },
  {
    label: LOGIN,
    roleId: 8,
  },
  {
    label: WORKLOAD_MANAGER,
    roleId: 9,
  },
  {
    label: TIMESHEET_MANAGER,
    roleId: 10,
  },
  {
    label: TEAM_BUDGET_MANAGER,
    roleId: 11,
  },
  {
    label: WORKLOAD_MANAGER_VIEW_ONLY,
    roleId: 12,
  },
  {
    label: FINANCIAL_MANAGER,
    roleId: 13,
  },
  {
    label: FINANCIAL_MANAGER_VIEW_ONLY,
    roleId: 14,
  },
];

export const accessRolesHash = keyBy(ACCESS_ROLES, 'roleId');

export type RoleType = {
  label: string,
  roleId: number
}