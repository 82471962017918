import { AppStateType } from "reducers";
import * as constants from "@constants";
import { createSelector } from "reselect";
import orderBy from "lodash/orderBy";
import keyBy from "lodash/keyBy";
import * as thunks from "thunk";

const selectReportsReducerRequestStatusesAndErrors = (state: AppStateType) =>
  state.reportsReducer.requestStatusesAndErrors;

const selectSuperAdminsState = (state: AppStateType) =>
  state.reportsReducer.super_admins;

const selectSuperAdminsData = createSelector(
  selectSuperAdminsState,
  selectReportsReducerRequestStatusesAndErrors,
  (data, requestStatusesAndErrors) => ({
    data,
    isUpdatingMember:
      requestStatusesAndErrors[thunks.updateSuperAdmin.typePrefix]?.isRequesting,
    updateMemberError:
      requestStatusesAndErrors[thunks.updateSuperAdmin.typePrefix]?.error,
    isFetchingMember:
      requestStatusesAndErrors[thunks.fetchSuperAdmins.typePrefix]?.isRequesting,
    fetchingMemberError:
      requestStatusesAndErrors[thunks.fetchSuperAdmins.typePrefix]?.error,
  })
);

const selectSuperAdminTableFilter = (state: AppStateType) =>
  state.filterReducer[constants.filterIds.superAdminTable];

export const selectSuperAdmins = createSelector(
  selectSuperAdminsData,
  selectSuperAdminTableFilter,
  ({ data: superAdmins, ...rest }, filter) => {
    // Sorting
    const sortedData = orderBy(
      superAdmins,
      filter.orderBy,
      filter.orderDirection
    );

    return { formattedData: sortedData, filter, ...rest };
  }
);

export const selectSuperAdminsHash = createSelector(
  selectSuperAdminsState,
  (data) => keyBy(data, "account_id")
);
